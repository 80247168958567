<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card class="my-8">
            <v-card-title>신청자 정보 / {{ this.$route.params.idx }}</v-card-title>
            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.is_admin"
                hide-details="auto"
                row
                class="mt-0"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              >
                <div class="subtitle-1 pr-4">
                  관리자 관리 여부
                </div>
                <v-radio label="Y" :value="true" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
                <v-radio label="N" :value="false" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
              </v-radio-group>
              <!-- <v-text-field
                v-model="model.formItem.location_type"
                label="장소 타입"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              /> -->
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.phone"
                label="신청자 연락처 (read only)"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
          </v-card>

          <v-card class="my-8">
            <v-card-title>장소 정보</v-card-title>
            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.location_type"
                hide-details="auto"
                row
                class="mt-0"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              >
                <div class="subtitle-1 pr-4">
                  장소
                </div>
                <v-radio label="집" value="home" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
                <v-radio
                  label="병원"
                  value="hospital"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
              </v-radio-group>
              <!-- <v-text-field
                v-model="model.formItem.location_type"
                label="장소 타입"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              /> -->
            </v-list>
            <v-list class="pa-4">
              <div class="d-flex">
                <div>
                  <v-select
                    ref="item__location_idx"
                    v-model="model.formItem.location_idx"
                    :items="regions"
                    label="시/도"
                    item-text="short_name"
                    :item-value="item => item.idx"
                    append-icon="expand_more"
                    dense
                    outlined
                    hide-details="auto"
                    :readonly="Boolean(model.item.extra_info.is_matched)"
                    :disabled="Boolean(model.item.extra_info.is_deleted)"
                    @input="onChangeRegion"
                  />
                </div>
                <div class="ml-4">
                  <v-select
                    ref="item__location_detail_idx"
                    v-model="model.formItem.location_detail_idx"
                    :items="cities"
                    label="시/군/구"
                    item-text="short_name"
                    :item-value="item => item.idx"
                    append-icon="expand_more"
                    dense
                    outlined
                    hide-details="auto"
                    :readonly="Boolean(model.item.extra_info.is_matched)"
                    :disabled="Boolean(model.item.extra_info.is_deleted)"
                  />
                </div>
              </div>
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.location_type_detail_1"
                label="장소 상세 1"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.location_type_detail_2"
                label="장소 상세 2"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="my-8">
            <v-card-title>환자 정보</v-card-title>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.name"
                label="환자 이름"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.age"
                label="나이"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.gender"
                hide-details="auto"
                row
                class="mt-0"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              >
                <div class="subtitle-1 pr-4">
                  성별
                </div>
                <v-radio label="남" value="male" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
                <v-radio label="여" value="female" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
              </v-radio-group>
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.weight"
                label="체중 (단위 kg)"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-textarea
                v-model="model.formItem.medical_information"
                label="진단정보"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-textarea
                v-model="model.formItem.more_information"
                label="필요 간호행위, 보조 및 환자 특이사항"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="my-8">
            <v-card-title>간병 정보</v-card-title>
            <v-list class="pa-4">
              <div class="caption">
                시작/종료일자
              </div>
              <DatePickerRangeType2
                ref="data_picker_range_type2"
                :from.sync="model.formItem.started_at"
                :to.sync="model.formItem.ended_at"
                start-date-ref="item__started_at"
                end-date-ref="item__ended_at"
                start-date-label="시작날짜"
                end-date-label="종료날짜"
                start-date-append-icon="calendar_today"
                end-date-append-icon="calendar_today"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.daily_payment"
                label="지급가능 급여"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.care_time_info"
                label="하루 간병시간"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card class="my-8">
            <v-card-title>요청사항</v-card-title>
            <v-list class="pa-4">
              <v-select
                ref="item__important_things_to_pick_career_idx"
                v-model="model.formItem.important_things_to_pick_career_idx"
                label="간병인 구인 시 가장 중요하다고 생각되는 항목"
                :items="importantThingItems"
                item-text="reason"
                item-value="idx"
                placeholder="선택해주세요"
                append-icon="expand_more"
                dense
                outlined
                hide-details="auto"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              />
            </v-list>
            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.want_carer_gender"
                hide-details="auto"
                row
                class="mt-0"
                :readonly="Boolean(model.item.extra_info.is_matched)"
                :disabled="Boolean(model.item.extra_info.is_deleted)"
              >
                <div class="subtitle-1 pr-4">
                  간병인 선호성별
                </div>
                <v-radio label="남" value="male" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
                <v-radio label="여" value="female" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
                <v-radio label="무관" value="any" on-icon="radio_button_checked" off-icon="radio_button_unchecked" />
              </v-radio-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col cols="auto">
          <v-btn v-if="mode === 'add'" class="mx-4" color="primary" @click="saveItem">
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">등록</span>
          </v-btn>

          <template v-if="model.item.extra_info.is_matched">
            매칭된 데이터는 수정,삭제할 수 없습니다.
          </template>
          <template v-else-if="model.item.extra_info.is_deleted">
            삭제된 데이터입니다.
          </template>
          <template v-else>
            <v-btn v-if="mode === 'modify'" class="mx-4" color="primary" @click="updateItem">
              <v-icon small>
                edit
              </v-icon>
              <span class="pl-2">수정</span>
            </v-btn>
            <v-btn
              v-if="mode === 'modify'"
              class="mx-4"
              color="error"
              @click="deleteConfirmSheetView = !deleteConfirmSheetView"
            >
              <v-icon small>
                delete
              </v-icon>
              <span class="pl-2">삭제</span>
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet v-model="deleteConfirmSheetView" persistent>
      <v-sheet class="text-center" height="250px">
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn class="mx-3" color="primary" @click="deleteItem">
            <span>Y</span>
          </v-btn>
          <v-btn class="mx-3" color="" @click="deleteConfirmSheetView = !deleteConfirmSheetView">
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
import DatePickerRangeType2 from '../_elements/form/DatePickerRangeType2'
import Patient from './model/patient'
import { PatientSchema, PatientFormSchema } from './model/patientSchema'
import { getAddressList } from '../address/model/addressFinder'
import Api from './model/api'

export default {
  components: {
    DatePickerRangeType2
  },
  props: ['value', 'location_idx', 'location_detail_idx'],
  data() {
    return {
      model: new Patient(),
      termCategory: '',
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: '',
      mode_text: '',
      importantThingItems: [],
      item: new PatientSchema(),
      formItem: new PatientFormSchema(),
      regions: [],
      cities: [],
      rules: {
        required: value => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    async location_idx() {
      await this.onChangeRegion()
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      this.model = this.value
      const { idx } = this.$route.params
      await this.getRegions()
      if (idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        // this.model.idx = parseInt(idx, 10)
        // await this.model.bindItem()
        await this.onChangeRegion()
      }
      this.importantThingItems = await Api.getImportThingItems()
    },
    async getRegions() {
      this.regions = await getAddressList()
    },
    async onChangeRegion() {
      this.cities = await getAddressList(this.model.formItem.location_idx)
    },
    async saveItem() {
      if (this.$refs.form.validate() === false) {
        return
      }

      const result = await this.model
        .add()
        .then()
        .catch(e => {
          console.log(e.response.status === 400)
          if (e.response.status === 400) this.$bus.$emit('errorMessage', '이미 등록된 연락처입니다.')
          else this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
        })
      if (result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      }
    },
    async updateItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const result = await this.model.update()
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const result = await this.model.delete()
      if (result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Patients.List') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/patients?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
