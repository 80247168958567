import { Api } from '@/libraries/api'
import Config from '@/config'
import axios from 'axios'

// import { Api, makeGetUrl } from '@/libraries/api'
import { PatientSchema, PatientFormSchema } from './patientSchema'

const Patient = class {
  #idx = null

  constructor() {
    this.item = new PatientSchema()
    this.formItem = new PatientFormSchema()
  }

  set idx(idx) {
    this.#idx = idx
  }

  get idx() {
    return this.#idx
  }

  #setSchemaMapper() {
    const bi = this.item.basic_info
    const ei = this.item.extra_info
    this.formItem.name = bi.name
    this.formItem.phone = bi.phone
    this.formItem.age = bi.age
    this.formItem.gender = bi.gender
    this.formItem.weight = ei.weight
    this.formItem.medical_information = ei.medical_information
    this.formItem.more_information = ei.more_information
    this.formItem.started_at = ei.started_at
    this.formItem.ended_at = ei.ended_at
    this.formItem.daily_payment = ei.daily_payment
    this.formItem.care_time_info = ei.care_time_info
    this.formItem.want_carer_gender = ei.want_carer_gender
    this.formItem.important_things_to_pick_career_idx = ei.important_things_to_pick_career_idx
    this.formItem.location_idx = ei.location_idx
    this.formItem.location_detail_idx = ei.location_detail_idx
    this.formItem.location_type = ei.location_type
    this.formItem.location_type_detail_1 = ei.location_type_detail_1
    this.formItem.location_type_detail_2 = ei.location_type_detail_2
    this.formItem.is_admin = ei.is_admin
  }

  getFormItem() {
    this.#setSchemaMapper()
    return this.formItem
  }

  async add() {
    const data = { ...this.formItem }
    const apiResult = await axios.post(`${Config.Api.Service.url}/patient`, data)
    const { result } = { ...apiResult.data }
    return result
  }

  async bindItem() {
    const url = `/admin/patient/${this.#idx}`
    const apiResult = await Api().get(url)
    const { item } = apiResult.data.data
    this.item = item
    this.#setSchemaMapper()
    return this.item
  }

  async update() {
    const url = `/admin/patient/${this.#idx}`
    const data = { ...this.formItem }
    const apiResult = await Api().put(url, data)
    const { result } = apiResult.data
    return result
  }

  async delete() {
    const url = `/admin/patient/${this.#idx}`
    const apiResult = await Api().delete(url)
    const { result } = apiResult.data
    return result
  }
}

export default Patient
